import React from "react";

const Popup = ({ onClose, children }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-white w-[90%] md:w-[40%] p-6 rounded-lg shadow-lg">
                <button
                    className="absolute top-2 right-2 text-lg text-black"
                    onClick={onClose}
                >
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};

export default Popup;
