import React from "react";
import locationImage from "../assets/model-villa.webp";
import locationImage2 from "../assets/model-villa2.webp";
import locationImage3 from "../assets/model-villa3.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./ThirdSection.css";

const Location = () => {
  return (
    <div className="px-8 md:px-28 md:pt-16">
    <div className="mt-11 mb-3 text-center md:w-full md:flex md:gap-4 md:justify-center">
    <h1 className="font-sans text-xl font-semibold text-black md:text-5xl">
    Model Villa Ready
    
    </h1>
    <h1 className="font-sans text-xl font-semibold text-[#9F372A] md:text-5xl">
    Limited Units, Contact Now
    </h1>
      </div>
        <div className="flex flex-col gap-10">
        <div className="mt-4 md:pt-10">
        <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        spaceBetween={30}
        slidesPerView={1}
        breakpoints={{
          768: {
            slidesPerView: 3, 
            spaceBetween: 30,
          },
        }}
        className="relative custom-swiper"
      >
          <SwiperSlide>
          <img
            src={locationImage}
            alt="Plot in Electronic City 1"
            className="object-cover w-full h-[180px] md:h-[310px] rounded-md"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={locationImage2}
            alt="Plot in Electronic City 2"
            className="object-cover w-full h-[180px] md:h-[310px] rounded-md"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={locationImage3}
            alt="Plot in Electronic City 3"
            className="object-cover w-full h-[180px] md:h-[310px] rounded-md"
          />
        </SwiperSlide>
          </Swiper>
        </div>
      </div>

    </div>
  );
};

export default Location;
