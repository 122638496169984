import React from "react";
import ContactForm from "./Components/SiteVisitForm";

const SiteVisit = () => {
  return (

    <div className="px-8 md:px-28 md:pb-20 ">
      <div className="flex flex-col pt-6 md:flex-row md:pt-12">
      <div className="w-full md:w-1/2 hidden md:flex md:justify-start md:items-center">
          <iframe
          title="Krishvi EVilla Location Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3891.0477919278937!2d77.74797837598675!3d12.775408319147422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae71f32a8dc80f%3A0x1b217d348757f1a7!2sKrishvi%20EVilla!5e0!3m2!1sen!2sin!4v1725111463522!5m2!1sen!2sin"
        className="h-[7rem] w-full md:h-[465px] md:w-[570px]"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
        <div className="md:w-1/2 md:py-2">
          <ContactForm/>
        </div>
      </div>
    </div>
  );
};

export default SiteVisit;
