import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa"; 
import Analytics from "./components/FirstSection";
import { useState, useEffect } from "react";
import HeroSection from "./components/BannerSection";
import Location from "./components/ThirdSection";
import SiteVisit from "./components/FourthSection";
import Footer from "./components/Footer";
import Banks from "./components/SecondSection";
import Popup from "./components/Components/Popup";
import ContactForm from "./components/Components/SiteVisitForm";

function App() {
  const [whatsappNumber] = useState("8861590724");
  const [phoneNumber] = useState("+8861590724");
  const [isPopupVisible, setIsPopupVisible] = useState(false); 

    const handleWhatsappClick = () => {
        window.open(`https://wa.me/${whatsappNumber}`, "_blank");
    };
    const handleCallClick = () => {
      window.open(`tel:${phoneNumber}`, "_self");
    };
  
    const handleClosePopup = () => {
      setIsPopupVisible(false);
    };
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsPopupVisible(true); 
      }, 4000);
  
      return () => clearTimeout(timer);
    }, []);
  
    return (
        <div>
            <HeroSection />
            <Analytics />
            <Banks />
            <Location />
            <SiteVisit />
        <Footer />
        <div
        onClick={handleCallClick}
        className="fixed bottom-[5.3rem] right-6 z-50 cursor-pointer rounded-full bg-green-600 p-[0.90rem] text-white md:block hidden" 
    >
        <FaPhoneAlt size={24} color="white" />
    </div>
            <div
                onClick={handleWhatsappClick}
                className="fixed bottom-20 right-20 z-50 cursor-pointer rounded-full bg-green-600 p-3 text-white md:block hidden "
                style={{ position: "fixed", bottom: 20, right: 20 }}
            >
                <FaWhatsapp size={32} color="white" />
        </div>
         {/* Popup Component */}
      {isPopupVisible && (
        <Popup onClose={handleClosePopup}>
          <h2 className="text-lg text-center font-normal mb-4">Please Fill In The Form</h2>
          <ContactForm />
        </Popup>
      )}
        </div>
    );
}

export default App;
