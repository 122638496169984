import React, {useState } from "react";
import Popup from "./Components/Popup"; 
import ContactForm from "./Components/SiteVisitForm";
import train from "../assets/location-icons/metro-1.svg";
import road from "../assets/location-icons/road-1.svg";
import plane from "../assets/location-icons/plane-1.svg";

const Banks = () => {

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleButtonClick = () => {
      setIsPopupVisible(true); 
  };

  const handleClosePopup = () => {
      setIsPopupVisible(false); 
  };


  return (
    <div className="px-8 pt-11 md:px-28 md:pt-24">
    <div className="text-center md:w-full md:flex md:gap-4 md:justify-center">
    <h1 className="font-sans text-xl font-semibold text-black md:text-5xl">
    Electronic City
    </h1>
    <h1 className="font-sans text-xl font-semibold text-[#9F372A] md:text-5xl">
    15 Mins from Chandapura
    </h1>
      </div>
      <div className="flex flex-col gap-4 pt-6 text-center md:w-full md:gap-6 md:py-12 md:pt-12 md:text-left">
      <p className="text-justify text-sm font-thin leading-[1.3rem] md:text-center md:text-base md:leading-6">
          Strategically located Villas in Chandapura, Near Electronic City, a well-planned, fast-growing neighborhood,
          and prime investment area, with easy access to Highway, Airport & NICE Road.
      </p>
      </div>

      <div className="pt-6 flex flex-col gap-7 md:flex-row md:items-center md:justify-between md:gap-20">
        
      <div className="flex flex-row gap-2 items-center bg-white rounded-lg md:flex md:flex-col md:gap-6">
      <div className="flex-shrink-0 md:mb-0 md:mr-4">
        <img
            src={train}
            alt="Small Icon"
            className="w-12 h-12 md:w-20 md:h-20"
        />
      </div>
      <div className="w-[2px] h-8 bg-[#9F372A] mx-4 md:hidden"></div>
      <div className="flex flex-col">
        <p className="text-xs mb-2 md:text-base">
          10 Mins to <b>Attibele Metro Station</b>
        </p>
        <p className="text-xs md:text-base">
          15 Mins to <b>Bommasandra Metro</b>
        </p>
          </div>
        </div>


      <div className="flex items-center gap-2 bg-white flex-row-reverse rounded-lg md:flex md:flex-col md:gap-6">
      <div className="flex-shrink-0 md:mb-0 md:mr-4">
        <img
            src={road}
            alt="Small Icon"
            className="w-12 h-12 md:w-20 md:h-20"
        />
      </div>
      <div className="w-[2px] h-8 bg-[#9F372A] mx-4 md:hidden"></div>
      <div className="flex flex-col">
        <p className="text-xs mb-2 md:text-base">
        STRR <b>- Satellite Town  Ring Road</b>
        </p>
        <p className="text-xs md:text-base">
        PRR <b>- Peripheral Ring Road</b>
        </p>
          </div>
        </div>


        <div className="flex flex-row items-center gap-2 bg-white rounded-lg md:flex md:flex-col md:gap-6">
      <div className="flex-shrink-0 md:mb-0 md:mr-4">
        <img
            src={plane}
            alt="Small Icon"
            className="w-12 h-12 md:w-20 md:h-20"
        />
          </div>
          <div className="w-[2px] h-8 bg-[#9F372A] mx-4 md:hidden"></div>
      <div className="flex flex-col">
        <p className="text-xs mb-2 md:text-base">
        Close proximity to the Upcoming
        </p>
        <p className="text-xs md:text-base">
        <b>Hosur International Airport</b>
        </p>
          </div>
          </div>
       </div>

      <div className="w-full md:mt-12 md:flex md:justify-center mt-3">
        <button
          className="mt-6 px-6 py-3 font-normal md:w-1/3 text-white bg-[#9F372A] w-full rounded-md shadow-[0_4px_6px_rgba(0,0,0,0.1)] hover:bg-[#862b20] transition duration-300"
          onClick={handleButtonClick}
        >
        Schedule Site Visit
          </button>
      </div>
      {isPopupVisible && (
        <Popup onClose={handleClosePopup}>
            <h2 className="text-lg text-center font-normal mb-4">Please Fill In The Form</h2>
            <ContactForm/>
        </Popup>
    )}
      
    </div>
  );
};

export default Banks;
