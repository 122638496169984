import { useState } from "react";
import Popup from "./Components/Popup"; 
import ContactForm from "./Components/SiteVisitForm";

const Footer = () => {
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

  const togglePrivacyPolicyModal = () => {
    setShowPrivacyPolicyModal(!showPrivacyPolicyModal);
    if (!showPrivacyPolicyModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const toggleDisclaimerModal = () => {
    setShowDisclaimerModal(!showDisclaimerModal);
    if (!showDisclaimerModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false); 

  const handleButtonClick = () => {
      setIsPopupVisible(true); 
  };

  const handleClosePopup = () => {
      setIsPopupVisible(false); 
  };

  return (
    <footer>
      {showPrivacyPolicyModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="max-h-full max-w-7xl overflow-y-auto rounded-lg bg-white p-8 relative">
            <button
              onClick={togglePrivacyPolicyModal}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-500 focus:text-gray-500 focus:outline-none"
              aria-label="Close"
            >
              <svg
                className="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="pb-4 text-xl font-bold">Privacy Policy:</h2>
            <p className="pb-4">
            This Privacy Policy governs the style within which Krishvi Project Pvt Ltd. and its subsidiaries, partners, agents, and affiliates collect, use, maintain, and disclose info collected from users of our website and microsite.

            Personal identification information:
            
            We could collect personal identification info from Users in various ways, including, but not limited to, when Users visit our website, purchase the account, fill out a form, and in connection with different activities, services, options, or resources we make available on our website.
            
            Users may be asked for, as appropriate, name, email address, mailing address, phone number.
            
            Users may, however, visit our Site anonymously.
            
            Users will forever refuse to provide personal identification info, except that it may prevent them from engaging in certain website-related activities.
            
            Non-personal identification information:
            
            We could collect non-personal identification info regarding Users whenever they interact with our website.
            
            Non-personal identification info could include the browser name, the type of computer and technical info regarding Users' means of connection to our website, such as the operating system and the internet service providers used, and other similar info.
            
            Web browser cookies:
            
            Our website could use “cookies” to enhance User experience.
            
            Users' applications program places cookies on their disc drive for record-keeping functions and generally to track info regarding them.
            
            Users may choose to set their web browser to refuse cookies or to alert Users when cookies are being sent.
            
            If they do so, note that some parts of the site might not operate properly.
            
            This is a standard operating procedure that is used across the internet.
            
            How we use collected information:
            
            Krishvi Project Pvt Ltd. may collect and use Users' personal information for the following purposes:
            
            - To improve customer service: Information provided by Users helps us respond to customer service requests and support needs more efficiently.
            - To personalize User experience: We could use info in the aggregate to understand how our Users as a group use the services and resources provided on our website.
            - To improve our Site: We could use feedback provided by Users to enhance our products and services.
            - To run a promotion, contest, survey, or other website feature.
            - To send Users info they agreed to receive regarding topics of interest to them.
            - To send periodic emails: We could use the email address to respond to inquiries, questions, and/or other requests of Users.
            
            If Users choose to join our list, they will receive emails about company news, updates, related product or service information, etc.
            
            If at any time Users would like to unsubscribe from receiving future emails, they can do so by contacting us.
            
            How we protect Users' information:
            
            We adopt appropriate data collection, storage, and processing practices and security measures to safeguard against unauthorized access, alteration, disclosure, or destruction of Users' personal info and data stored on our website.
            
            As with data security, there are limits to its effectiveness, and we indemnify ourselves in the event of an attack that is difficult to defend against.
            
            We also will do our best to retrieve any data that’s lost as per available resources.
            
            Sharing personal information of Users:
            
            We don’t sell, trade, or rent Users' personal identification info to others.
            
            We could share generic collective demographic info not connected to any personal identification info regarding Users with our subsidiaries, business partners, trusted affiliates, and advertisers for the purposes outlined above.
            
            Changes to this privacy policy:
            
            Krishvi Project Pvt Ltd. shall update this privacy policy at its sole discretion.
            
            Users are advised to check this page for any changes in the privacy policy and to stay informed about how their personal info is protected.
            
            Users hereby acknowledge and agree that it’s their responsibility to review this privacy policy periodically and become aware of modifications.
            
            Your acceptance of these terms:
            
            By using this website, Users signify their acceptance of this policy as may be modified from time to time.
            
            Users are advised not to access this website if they do not agree with our privacy policy.
            
            The above-mentioned privacy policy shall be applicable to the information and data collected by our call centers as well.
            </p>
          </div>
        </div>
      )}

      {showDisclaimerModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="max-h-full max-w-4xl overflow-y-auto rounded-lg bg-white p-8 relative">
            <button
              onClick={toggleDisclaimerModal}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-500 focus:text-gray-500 focus:outline-none"
              aria-label="Close"
            >
              <svg
                className="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="mb-4 text-xl font-bold">Disclaimer:</h2>
            <p className="mb-4">
            Thank you for visiting our website.
              We are in compliance of the newly introduced housing laws i.e. the Real Estate (Regulation and Development) Act,
              2016 and the rules and regulations notified/to be notified thereunder. Accordingly, we are in the process of updating the information
              and materials on the website.
              Hence, until our website is duly revised and updated, the information including the images and materials presently available on
              the website shall not be deemed to constitute any advertisement, invitation, solicitation, offer or sale of any of apartments / units in
              our Projects and we shall not accept any such bookings based on reliance of such currently available information, images and material on
              the website and further shall not be responsible for any consequences of any action taken by any person or authority relying on material/information
              or otherwise.
              You are therefore requested to directly verify all details and aspects of any proposed booking/acquisition of units/premises, directly with
              our authorised representatives. Please do not rely on the information contained on this website, until our revision and updation is complete.
              Please write to our Sales Team on sales@krishvi.com for the updated sales and marketing information and collaterals.
              We thank you for your patience and understanding.
            </p>
          </div>
        </div>
      )}

      <div className="px-8 md:px-28 md:pb-20 md:hidden">
      <div className="flex flex-col pt-8 pb-6 md:flex-row md:pt-12">
      <div className="w-full md:w-1/2">
            <iframe
            title="Krishvi EVilla Location Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3891.0477919278937!2d77.74797837598675!3d12.775408319147422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae71f32a8dc80f%3A0x1b217d348757f1a7!2sKrishvi%20EVilla!5e0!3m2!1sen!2sin!4v1725111463522!5m2!1sen!2sin"
        className="h-[13rem] w-full md:h-[445px] md:w-[500px]"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
      </div>
      </div>
      <div className="md:bg-[#9F372A] md:flex md:flex-row md:items-center md:justify-around md:w-full md:h-full flex flex-col pb-16 gap-3 md:py-5">
      <div className="text-center text-[#505050] md:text-white text-[8.5px] md:text-sm md:font-normal font-semibold md:w-auto">
        Project by Krishvi Projects - PRM/KA/RERA/1251/308/PR/190123/005627
      </div>
      <div className="flex items-center justify-center gap-2 md:flex-row md:gap-4 md:ml-5">
        <button
          className="text-[8.5px] md:text-sm text-[#505050] md:text-white" 
          onClick={toggleDisclaimerModal}
        >
          Disclaimer
        </button>
        <span className="text-[8.5px] md:text-white md:text-sm"> | </span>
        <button
          className="text-[8.5px] md:text-sm text-[#505050] md:text-white"
          onClick={togglePrivacyPolicyModal}
        >
          Privacy Policy
        </button>
      </div>
    </div>
    
      <div className="flex border-r gap-[1px] border-white bg-white fixed bottom-0 z-[99999999] w-[100%] md:hidden">
        <div className="flex items-center justify-between bg-[#9F372A] p-3 text-white w-full">
        <div className="flex flex-grow md:ml-5">
          <button
            className="flex-grow text-md md:text-sm relative  h-full flex items-center justify-center"
            onClick={handleButtonClick}
          >
            Enquire
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between bg-[#9F372A] p-3 text-white w-full">
      <div className="flex flex-grow md:ml-5">
        <a
          href="tel:+8861590724"
          className="flex-grow text-md md:text-sm relative h-full flex items-center justify-center text-center"
        >
          Call
        </a>
      </div>
      </div>
      
      <div className="flex items-center justify-between bg-[#9F372A] p-3 text-white w-full">
      <div className="flex flex-grow md:ml-5">
        <a
          href="https://wa.me/918861590724?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services."
          target="_blank"
          rel="noopener noreferrer"
          className="flex-grow text-md md:text-sm h-full flex items-center justify-center text-center"
        >
          WhatsApp
        </a>
      </div>
        </div>
        </div>
        
      
      {isPopupVisible && (
        <Popup onClose={handleClosePopup}>
            <h2 className="text-lg text-center font-normal mb-4">Please Fill In The Form</h2>
            <ContactForm/>
        </Popup>
    )}
      
    </footer>
  );
};

export default Footer;
